<template>
  <div>
    <b-button
      variant="primary"
      @click="addCampaign"
      class="float-right mb-2"
      size="sm"
    >
      <i class="ri-add-line"></i> Campaign
    </b-button>

    <b-modal id="campaign-modal" title="Add Campaign" hide-footer>
      <div v-if="loading">
        <div class="text-center text-danger my-4">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
      <div v-else>
        <FormulateForm
          class="login-form"
          v-model="formValues"
          @submit="submitForm"
        >
          <FormulateInput
            name="campaign_name"
            type="text"
            label="Campaign name"
            placeholder="Campaign name"
            validation="required"
          />
          <FormulateInput
            name="company"
            :options="formatedCompanies"
            type="select"
            placeholder="company"
            label="Company"
          />
          <div class="custom-control custom-radio mt-2 row d-flex">
            <FormulateInput
              v-model="plan_type"
              :options="{ existing_plan: 'Existing', new_plan: 'New' }"
              type="radio"
              label="Choose the Plan Type"
            />
          </div>

          <div v-if="plan_type == 'new_plan'" class="card card-body">
            <FormulateInput
              v-model="customPlan.name"
              type="text"
              label=" name"
              placeholder="Plan name"
              validation="required"
            />
            <FormulateInput
              v-model="customPlan.cost_per_km"
              type="text"
              label="Cost per KM"
              placeholder="Cost/KM"
              validation="optional|number"
            />
            <FormulateInput
              v-model="customPlan.price"
              type="text"
              label="price"
              placeholder="Plan price"
              validation="optional|number"
            />
            <FormulateInput
              v-model="customPlan.no_of_days"
              type="text"
              label="Number of days"
              placeholder="Number of days"
              validation="optional|number"
            />
            <FormulateInput
              v-model="customPlan.no_of_vehicles"
              type="text"
              label="Number of vehicles"
              placeholder="Number of vehicles"
              validation="optional|number"
            />
          </div>
          <div v-else>
            <FormulateInput
              name="plan_id"
              :options="formatedPlan"
              type="select"
              placeholder="Here is your plan"
              label="Plans"
            />
          </div>

          <FormulateInput
            type="date"
            name="start_within"
            label="Start within"
            validation="optional"
          />
          <FormulateInput
            name="cordinates"
            type="map-input"
            label="Location"
            validation="required"
          />

          <div class="float-right">
            <FormulateInput
              type="submit"
              :label="creating ? 'Creating...' : 'Add Campaign'"
            />
          </div>
        </FormulateForm>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { plans } from "@/config/api/plans";
import { company } from "@/config/api/company";
import { campaigns } from "@/config/api/campaigns";
export default {
  data() {
    return {
      customPlan: {
        name: "",
        cost_per_km: 100,
        price: "",
        no_of_days: 30,
        no_of_vehicles: 10,
      },
      plan_type: "existing_plan",
      formValues: {},
      plans: [],
      companies: [],
      loading: true,
      adding: false,
      creating: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    formatedPlan() {
      return this.plans.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
    },
    formatedCompanies() {
      return this.companies.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.loadAllCompany();
      await this.loadAllPlans();
      this.loading = false;
    },
    addCampaign() {
      this.$bvModal.show("campaign-modal");
    },
    submitForm(value) {
      const data = { ...value };
      if (this.plan_type == "new_plan") {
        data.plan = this.customPlan;
      }
      data.start_within = this.moment(data.start_within).format("MM-DD-YYYY");
      data.cordinates = [data.cordinates.lat, data.cordinates.lng];
      const api = campaigns.createCampaign;
      api.data = data;
      this.creating = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("New Campaign created!", {
            title: "Campaign",
            variant: "success",
            solid: true,
          });
          this.$emit("added");
          this.$bvModal.hide("campaign-modal");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Campaign is not created!,${err.response.data.message}`,
            {
              title: "Campaign",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.creating = false;
        });
    },
    loadAllCompany() {
      const api = company.getAll;
      this.generateAPI(api).then((res) => {
        this.companies = res.data.companies;
      });
    },
    loadAllPlans() {
      const api = plans.getActivePlans;

      this.generateAPI(api).then((res) => {
        this.plans = res.data.plans;
      });
    },
  },
};
</script>

<style>
</style>