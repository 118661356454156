<template>
  <div class="row" style="
      border: 1px solid #ced4d9;
      margin: 0;
      border-top: none;
      padding-bottom: 18px;
    ">
    <div class="col-12">
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <!-- <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                @change="pageSizeChange"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div> -->
        </div>
        <!-- Search -->
        <!-- <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div> -->
        <!-- End search -->
      </div>

      <!-- Table -->
      <div class="table-responsive mb-0">
        <b-table :items="campaignData" :fields="fields" responsive="sm" :busy="isBusy" :per-page="perPage"
          tbody-tr-class="rowClass" @row-clicked="campignSelected" show-empty>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty>
            <p style="text-align: center; margin-top: 70px">No campaigns</p>
          </template>
          <template #cell(company)="row">
            <strong> Name </strong> {{ row.item.campaign_name }} <br />
            <strong>Company Name:</strong> {{ row.item.company.name }} <br />
            <strong>Trade Licence:</strong>
            {{ row.item.company.trade_license_number }} <br />
          </template>
          <template #cell(plan)="row">
            <strong>Name:</strong> {{ row.item.plan.name }} <br />
            <strong>No. of vehicles:</strong>
            {{ row.item.plan.no_of_vehicles }} <br />
            <strong>Price:</strong> {{ row.item.plan.price }}
          </template>

          <template #cell(updatedAt)="row">
            {{ moment(row.item.updatedAt).format("lll") }}
          </template>

          <template #cell(drivers)="row">
            <strong>No. of Driver required:</strong>
            {{ row.item.no_of_drivers_required }}<br />
            <strong>No. of Driver Assigned:</strong>{{ row.item.no_of_drivers_assigned }}
          </template>
          <template #cell(createdAt)="row">
            {{ moment(row.item.createdAt).format("lll") }}
          </template>
          <template #cell(badge)="row">
            <b-badge variant="danger" v-if="row.item.no_of_drivers_assigned >= row.item.no_of_drivers_required">
              Driver Full
            </b-badge>
            <div
              v-else-if="!row.item.recurring && moment() >= moment(row.item.started_at).add(row.item.no_of_days, 'days')">
              <b-badge variant="danger">
                Expired {{ moment(row.item.started_at).add(row.item.no_of_days, 'days').startOf('day').fromNow() }}
              </b-badge>
              <br>
              <b-badge variant="danger">
                Started {{ moment(row.item.started_at).format("LLL") }}
              </b-badge>
              <br>
              <b-badge variant="danger">
                No. of Days {{ row.item.no_of_days }}
              </b-badge>
              <br>
              <b-badge variant="danger">
                Expired {{ moment(row.item.started_at).add(row.item.no_of_days, 'days').format('LLL') }}
              </b-badge>
            </div>
          </template>
          <template #cell(action)="row">
            <b-button size="sm" variant="danger" @click="deleteCampaign(row.item._id)">
              Delete
            </b-button> 
            <b-button size="sm" variant="primary" class="ml-1" @click="finishCampaign(row.item._id)">
              Finish
            </b-button>
          </template>
        </b-table>
        <div>

          <b-modal id="updatePriceModal" ref="updatePriceModal" title="Update Price" hide-footer>
            <b-form-group id="input-group-1" label="Enter Price:" label-for="input-1"
              description="The new price of the selected plan">
              <b-form-input type="number" v-model="updatedPrice" placeholder="Please enter the price">
              </b-form-input>
            </b-form-group>
            {{this.selectedPlanPrice}}
            {{this.selectedCampaign}}
            <div class="mt-2 text-center">
              <b-button @click="createDrivers">Update</b-button>
            </div>
          </b-modal>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
              </b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
import Swal from "sweetalert2";
export default {
  props: ["status", "reload"],
  data() {
    return {
      campaignData: [],
      removeTms: false,
      isBusy: false,
      currentPage: this.$store.getters["campaign/currentPage"],
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "plan", sortable: false },
        { key: "plan_price", sortable: false },
        { key: "company", sortable: false },
        { key: "drivers", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "badge", sortable: false },
        { key: "action", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.$store.getters["campaign/rows"];
    },
  },

  created() {
    this.getAllCampigns(this.currentPage);
  },
  methods: {

    updatePage(page) {
      this.$store.dispatch("campaign/changePage", page);
      this.getAllCampigns(page);
    },
    getAllCampigns(page = 1) {
      this.isBusy = true;
      const api = campaigns.list;
      api.params = {
        status: this.status,
        page: page,
      };
      this.generateAPI(api)
        .then((res) => {
          this.campaignData = res.data.message.totalData;
          this.$store.dispatch(
            "campaign/changeTotalRow",
            (res.data.message.totalCount &&
              res.data.message.totalCount.count) ||
            0
          );

          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },

    // rowClass(item, type) {
    //     if (!item || type !== 'row') return
    //     if (item.status === 'awesome') return 'table-success'
    //   },

    campignSelected(row) {
      this.$store.dispatch("campaign/selectCampaign", row);
      this.$router.push({ name: "CampaignsDetails", params: { id: row._id } });
    },
    pageSizeChange(val) {
      this.getAllCampigns(this.currentPage);
      this.perPage = val;
    },
    async reloadData() {
      await this.$store.dispatch("campaign/changePage", 1);
      await this.getAllCampigns(1);
      this.$emit("reloaded");
    },
    deleteCampaign(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteAction(id);
        }
      });
    },
    deleteAction(id) {
      const api = campaigns.deleteCampaign;
      api.id = id;
      this.generateAPI(api)
        .then(() => {
          if (this.campaignData.length === 1) {
            const page =
              this.currentPage == 1
                ? this.currentPage
                : parseInt(this.currentPage) - 1;

            this.$store.dispatch("campaign/changePage", page);
            this.getAllCampigns(page);
            this.currentPage = page;
          } else {
            this.getAllCampigns(this.currentPage);
          }

          Swal.fire("Deleted!", "Campaign has been deleted.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Error!",
            `Campaign is not deleted,${err.response.data.error}`,
            "error"
          );
        });
    },

    finishCampaign(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Finish",
        denyButtonText: "Finish + remove TMS",
        showDenyButton: this.status === 3 ? true : false,
        denyButtonColor: "#34c38f"
      }).then((result) => {
        if (result.value) {
         this.finishAction(id);
        } else if (result.isDenied) {
          console.log("deny");
          this.removeTms = true;
          console.log("removeTms", this.removeTms);
          this.finishAction(id);
        }
      });
    },
    finishAction(id) {
      console.log("finish with tms")
      const api = campaigns.finishCampaign
      api.data = { campaign_id: id, removeTms: this.removeTms };
      this.generateAPI(api)
        .then(() => {
          if (this.campaignData.length === 1) {
            const page =
              this.currentPage == 1
                ? this.currentPage
                : parseInt(this.currentPage) - 1;

            this.$store.dispatch("campaign/changePage", page);
            this.getAllCampigns(page);
            this.currentPage = page;
          } else {
            this.getAllCampigns(this.currentPage);
          }

          Swal.fire("Finished!", "Campaign has been finished.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Error!",
            `Campaign is not finished,${err.response.data.error}`,
            "error"
          );
        });
    },
  },
  watch: {
    reload(val) {
      if (val) {
        this.reloadData();
      }
    },
  },
};
</script>
<style>
.rowClass {
  cursor: pointer;
}

.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>
