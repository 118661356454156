export const company = {
  getDetails: {
    url: "admin/company/getCampaignsOfCompany/",
    method: "GET",
    id: null,
    params: null,
  },
  getAll: {
    url: "admin/application/companies",
    method: "GET",
  },

  changeCompany: {
    url: "admin/campaign/changeCompany/",
    method: "PUT",
    id: null,
  },
};
